import ButtonLink from 'components/shared/ButtonLink.styled';
import styled from 'styled-components';
import { getPublicPath } from 'utils/paths';
import { color, container, fontSize, screen, spacing } from '../../theme';

export const StyledError = styled.div`
  margin-top: -${spacing('xl4')};
  background-image: ${() =>
    `url('${getPublicPath(
      '/images/404/bg-404.svg'
    )}'), linear-gradient(180deg,#3b3552,#3b3552 57%,#df5440 153%)`};
  background-position: top;
  background-size: 100% 100%;
  padding-top: ${spacing('xl5')};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export const StyledErrorStars = styled.div`
  position: absolute;
  inset: 0;
  background-image: ${() =>
    `url('${getPublicPath('/images/404/stars-404.svg')}')`};
  background-color: transparent;
  background-repeat: repeat-x;
  background-position: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 0;

  @media (min-width: ${screen('md')}) {
    background-position: top;
  }

  > div {
    width: 100vw;
    height: 100vh;
    position: relative;
    transform: rotate(145deg);
  }
`;

export const StyledErrorShootingStar = styled.div`
  position: absolute;
  height: 2px;
  background: linear-gradient(-45deg, #f67784, rgba(246, 119, 132, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 4px #f67784);
  animation: tail 4s ease-in-out infinite, shooting 4s ease-in-out infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    transform: translateX(50%) rotate(45deg);
    border-radius: 100%;
    animation: shining 4s ease-in-out infinite;
  }

  &::after {
    transform: translateX(50%) rotate(-45deg);
  }

  &:first-child {
    top: 37vw;
    left: 13vw;
    animation-delay: 6.88s;

    &::before,
    &::after {
      animation-delay: 6.88s;
    }
  }

  &:nth-child(2) {
    top: 44vw;
    left: 34vw;
    animation-delay: 2.55s;

    &::before,
    &::after {
      animation-delay: 2.55s;
    }
  }

  &:nth-child(3) {
    top: 51vw;
    left: 13vw;
    animation-delay: 8438ms;

    &::before,
    &::after {
      animation-delay: 8438ms;
    }
  }

  &:nth-child(4) {
    top: 58vw;
    left: 14vw;
    animation-delay: 8742ms;

    &::before,
    &::after {
      animation-delay: 8742ms;
    }
  }

  &:nth-child(5) {
    top: 65vw;
    left: 80vw;
    animation-delay: 4275ms;

    &::before,
    &::after {
      animation-delay: 4275ms;
    }
  }

  @keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }

  @keyframes shooting {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(300px);
    }
  }

  @keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    to {
      width: 0;
    }
  }
`;

export const StyledErrorContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing('md')};
  max-width: ${container('lg')};
  padding: 0 ${spacing('md')};
  margin: auto;
  color: ${color('white')};
  text-align: center;
  font-size: ${fontSize('lg')};
  z-index: 1;
  padding-bottom: ${spacing('xl5')};

  img {
    display: block;

    @media (max-width: ${screen('mdMax')}) {
      max-width: ${spacing('xl6')};
    }
  }

  ${ButtonLink} {
    margin-top: ${spacing('xl3')};
  }
`;

export const StyledErrorMountains = styled.div`
  align-items: flex-end;
  background-image: ${() =>
    `url('${getPublicPath('/images/404/mountain-center.png')}')`};
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-position: top;
  min-height: 24rem;
  width: 100%;

  @media (max-width: ${screen('mdMax')}) {
    min-height: auto;
  }

  .left {
    margin-right: ${spacing('lg')};

    img {
      width: 100%;
      display: block;
    }
  }

  .right {
    margin-left: ${spacing('lg')};

    img {
      width: 100%;
      display: block;
    }
  }
`;
